import Highcharts from "highcharts/highcharts";
import {
  barChartBorderRadius,
  chartBackgroundColor,
  columnTooltipFormatter,
  crosshairColor,
  crpBarChartYAxisOptions,
  lineColor,
  plotOptionsBarBorderColor,
  plotOptionsBarBorderWidth,
  plotOptionsBarGrouping,
  plotOptionsBarShowInLegend,
  plotOptionsBarThreshold,
  xAxisLabelStyle,
  xAxisTickWidth,
} from "./globals";
import { riskZonesGreenToRed } from "./risk_colors";

function parseJSON(jsonString: string) {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return null;
  }
}

document.addEventListener("turbolinks:load", () => {
  let charts: NodeListOf<HTMLElement> = document.querySelectorAll(
    "[data-chart='risk_by_domain']",
  );

  if (charts) {
    Array.from(charts).forEach((chart) => {
      const crpData = parseJSON(chart.dataset.riskByDomain);
      const domainPercentiles = parseJSON(chart.dataset.domainPercentiles);
      const comparativeCrpData = parseJSON(chart.dataset.comparativeCrpData);
      const resourceNames = parseJSON(chart.dataset.resourceNames);
      const scores = parseJSON(chart.dataset.scores);

      Highcharts.chart({
        chart: {
          renderTo: chart.id,
          backgroundColor: chartBackgroundColor,
          type: "bar",
          height: 250,
        },
        title: {
          text: "",
        },
        xAxis: [
          {
            labels: {
              style: xAxisLabelStyle,
            },
            lineColor: lineColor,
            tickColor: lineColor,
            categories: crpData.categories,
            crosshair: {
              color: crosshairColor,
            },
            tickWidth: xAxisTickWidth,
          },
          {
            visible: !!domainPercentiles,
            opposite: true,
            categories: !!domainPercentiles ? domainPercentiles : [],
            labels: {
              style: {
                fontSize: "12px",
                fontWeight: "bold",
              },
            },
            lineWidth: 0,
            tickWidth: 0,
            title: {
              text: "Percentile Score",
              style: {
                color: "gray",
                fontSize: "14px",
                fontWeight: "bold",
              },
            },
          },
        ],
        yAxis: crpBarChartYAxisOptions,
        tooltip: {
          enabled: true,
          formatter: function () {
            return columnTooltipFormatter(
              this,
              resourceNames,
              comparativeCrpData,
              scores,
            );
          },
        },
        plotOptions: {
          bar: {
            animation: true,
            borderColor: plotOptionsBarBorderColor,
            borderWidth: plotOptionsBarBorderWidth,
            grouping: plotOptionsBarGrouping,
            showInLegend: plotOptionsBarShowInLegend,
            threshold: plotOptionsBarThreshold,
            zones: riskZonesGreenToRed,
          },
          series: {
            borderRadiusTopLeft: barChartBorderRadius,
            borderRadiusTopRight: barChartBorderRadius,
            cursor: "pointer",
            point: {
              events: {
                click: function () {
                  location.href = this.options.url;
                },
              },
            },
          },
        },
        series: [
          {
            type: "bar",
            name: "container",
            showInLegend: false,
            animation: false,
            data: crpData.container,
            tooltip: {
              followPointer: true,
            },
          },
          {
            type: "bar",
            xAxis: 1,
            name: "risk",
            showInLegend: false,
            data: crpData.values,
            states: {
              inactive: {
                opacity: 1,
              },
              hover: {
                enabled: true,
              },
            },
          },
          {
            name: "",
            visible: comparativeCrpData,
            data: comparativeCrpData,
            pointStart: 0,
            pointInterval: 1,
            lineWidth: 0,
            type: "line",
            color: "black",
            showInLegend: false,
            states: {
              hover: {
                enabled: false,
              },
            },
            marker: {
              fillColor: "lightgray",
              lineWidth: 1,
              lineColor: "black",
            },
          },
        ],
      });
    });
  }
});
